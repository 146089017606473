import React, { useEffect, useState } from "react";
import styles from "./index.module.css"
import logo192 from "../../images/logo192.png"
import ScrollableList from "../../component/ScrollableList";
import ClientDataInput from "../../component/ClientDataInput";
import Banner from "../../component/Banner";

export default function Main(props){

    const featureList = [
        "列出見不見之形。 例如: 天干丁己，組合成午",
        "列出暗拱地支。例如:子寅拱丑，巳丑拱酉",
        "列出原局、流年、流月配合以上結果計算合化、合局、天克地沖和刑冲破害",
        "點擊主中餘氣切換立極點",
        "列出桃花、驛馬、陽刃、祿位和十二長生",
        "以八字和用神， 查詢每年跟太歲的關係",
        "根據性別和陰陽日元，列出十神和親屬朋友的關係",
        "以八字反查詢出生日期，方便閱讀古書"]

    return (
        <>
          <Banner />
  
          <div className={`${styles.fullScreenContainer} `}>
  
  
            <div className={styles.gridContainer1Columns}>
              <div>特色:</div>
  
              <div className={styles.gridContainerSingleRowLayoutSpaced}>
                {/* {window.innerWidth || document.documentElement.clientWidth } */}
                {/* <img src={logo192}  className={styles.logoWidth} /> */}
                <div>
                  <ScrollableList featureList={featureList} />
                </div>
  
              </div>
              <center>
                <ClientDataInput
                  // getBirthDate={getBirthDate}
                  setInputDate={props.setInputDate} inputDate={props.inputDate}
                  isMale={props.isMale} setIsMale={props.setIsMale}
                  requireDateComfirmation={true} />
              </center>
  
  
            </div>
          </div>
        </>
      )

}