import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.css"

import { useLocation, useNavigate } from 'react-router-dom';
//import VToolbar from "./VToolbar";
// import ScrollableList from "../../component/ScrollableList"
import useScreenSizeCheck from "../../hook/useScreenSizeCheck";
import ClientDataInput from "../../component/ClientDataInput";

import { get } from '../../axios'
import { compareBirthday } from "../../Helper/DateTimeHelper";
import { globalData } from "../../Helper/global";
// import { parseLetterAddressAllDate } from "../../Helper/AddressObj"

//Google analytics
import { initializeAnalytics, sendGAPageViewCurrentPage, sendGAPageView } from "../../Helper/analytics"

//mui
// import { Help } from "@mui/icons-material";
import { CircularProgress } from '@mui/material';

import Tabs from 'react-responsive-tabs'; //tabpage
import 'react-responsive-tabs/styles.css';//tabpage

//Page
import Main from "../Main";
import Simple from "../Simple";
import UseGod from "../Usegod"
import Advanced from "../Advanced";
import Hint from "../Hint";
import Accessment from "../Accessment";
import Events from "../Events";
import Banner from "../../component/Banner";



export default function Index(props) {

  // const isSmallScreen = useScreenSizeCheck();
  const location = useLocation()
  //user input
  const [isMale, setIsMale] = useState(true)
  const [inputDate, setInputDate] = useState()

  //cache result
  const [eightLettersResult, setEightLettersResult] = useState()  // store the result chineseDate get request
  const [yearfortune100Result, setYearfortune100Result] = useState()  // store the result of yearfortune100 api
  const [addressSchema, setAddressSchema] = useState()    //the json content of address.json

  const [horosopeResult, setHorosopeResult] = useState()  //the reply of horosope api
  const [analysisResult, setAnalysisResult] = useState()  //the reply of analysis api

  const [msonIdPartyShowList, setMsonIdPartyShowList] = useState({})//For mson selector passed from eightletter to horosope
  const [letterAddressAllDateObj, setLetterAddressAllDateObj] = useState(); //hold data upon user's selected year month

  //for selection ui in YearFortune
  const selectedLetterIndexValue = "4-O"
  const [selectedLetterIndex, setSelectedLetterIndex] = useState(selectedLetterIndexValue)
  const [selectedLetter, setSelectedLetter] = useState()
  const [selected10YearRange, setSelected10YearRange] = useState()
  const [selectedYear10, setSelectedYear10] = useState(0)
  const [selectedYear10IndexNumber, setSelectedYear10IndexNumber] = useState(0)
  const [selectedYear, setSelectedYear] = useState(0)   //set selected year in YearFortune
  const [selectedMonth, setSelectedMonth] = useState(0) //set selected month in YearFortune

  //for record data ready status
  const [year10Ready, setYear10Ready] = useState(false) //record whether yearfortune is ready
  const [isAnalysisReady, setIsAnalysisReady] = useState(false);
  const [isDLetterReady, setIsDLetterReady] = useState(false);
  // const [isYearFortuneReady, setIsYearFortuneReady] = useState(false);  

  //for extra info
  const extraInfoMaskValue = "11111111111111111111"
  const [extraInfoMask, setExtraInfoMask] = useState(extraInfoMaskValue)



  //UI reference for scroll to hide date
  const dateRef = useRef(null);

  /**
   * Support bookmark url
   */
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'inputDate' && pathParts[3] === 'ismale') {

      //for checking birthday and filter outdated server reply
      globalData.inputDate = pathParts[2]

      //put data into dateTimePicker and checkbox
      setInputDate(pathParts[2]);
      setIsMale(pathParts[4] === 'true');

    }
  }, [location.pathname]);






  useEffect(() => {
    //downloading json content of address.json when component loaded
    fetch('/config/address.json')
      .then(response => response.json())
      .then(data => setAddressSchema(data));

    //init google analytics
    initializeAnalytics()

  }, []);

  /**
 * Reset old data when inputDate or isMale change
 */
  useEffect(() => {

    //update url
    if (!inputDate) return
    const newLocation = `/inputDate/${inputDate}/ismale/${isMale}`
    window.history.replaceState(null, '', newLocation);

    //record in GA when a valid datetime changed, or gender changed in URL or UI
    sendGAPageView(newLocation)

    resetOldData()

    //Step1. get birthdate from server
    getBirthDateFromServer()

  }, [inputDate, isMale])

  //Step2. get yearfortune100 from server
  useEffect(() => {
    if (eightLettersResult) {
      getYearFortune(inputDate, isMale)
      // getHoroscopeAnalysis()
    }
  }, [eightLettersResult]) //TODO when switch tab page


  const getYearFortune = (inputDate, isMale) => {
    get(`api/birthday/yearfortune100/${inputDate}/ismale/${isMale}?t=${Date.now()}`).then(res => {
      if (res.data.success) {
        if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
          setYearfortune100Result(Object.assign({}, res.data.data))
          setYear10Ready(true)
          console.log("2/3: yearfortune100 result is ready.")
        } else {
          console.log("YearFortune100 birthday " + res.data.data.birthday + " is not up to date.")
        }
      }
    })
  }


  const resetOldData = () => {
    //reset old data
    setSelectedYear10(0)
    setSelectedYear(0)
    setSelectedMonth(0)
    setSelectedLetter(undefined)
    setSelectedLetterIndex(selectedLetterIndexValue)


    setYearfortune100Result(undefined)
    setYear10Ready(false)

    setEightLettersResult(null)
    setHorosopeResult(null)
    setAnalysisResult(null)
    setIsAnalysisReady(false)


    setExtraInfoMask(extraInfoMaskValue)
    setMsonIdPartyShowList({})

  }

  //#region For eightletters to get analysis result

  // Replace the individual refs with a single object ref
  const lastGetConditionAnalysis = useRef({
    inputDate: null,
    isMale: null,
    yearFortune: null,
    year: null,
    month: null
  });

  // Update the getAnalysisOriginal function to use the new ref object
  const getAnalysisOriginal = (selectedYear10, selectedYear, selectedMonth = 0) => {
    // Check if any relevant values have changed since last get
    if (inputDate !== lastGetConditionAnalysis.current.inputDate
      || isMale !== lastGetConditionAnalysis.current.isMale
      || selectedYear10 !== lastGetConditionAnalysis.current.yearFortune
      || selectedYear !== lastGetConditionAnalysis.current.year
      || selectedMonth !== lastGetConditionAnalysis.current.month
    ) {
      return get(`api/birthday/analysis/${inputDate}/ismale/${isMale}/fortune/${selectedYear10}/year/${selectedYear}/month/${selectedMonth}?t=${Date.now()}`)
        .then(res => {
          if (res.data.success) {
            if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
              console.log("3/3: Analysis result is ready.");

              // Update all values in the ref object
              lastGetConditionAnalysis.current = {
                inputDate,
                isMale,
                yearFortune: selectedYear10,
                year: selectedYear,
                month: selectedMonth
              };

              return res.data.data;
            } else {
              console.log("Horosope birthday " + res.data.data.birthday + " is not up to date.");
              return null;
            }
          }
          return null;
        });
    }
    return Promise.resolve(null); // Return a resolved promise if no request needed
  }

  //#endregion

  //#region For horoscope to get analysis result



  const lastGetConditionHoroscope = useRef({
    inputDate: null,
    isMale: null,
    yearFortune: null,
    year: null,
    month: null
  });
  const getHoroscopeAnalysis = () => {
    if (inputDate && isMale !== undefined && compareBirthday(inputDate, globalData.inputDate)
      && (lastGetConditionHoroscope.current.inputDate !== inputDate || lastGetConditionHoroscope.current.isMale !== isMale)
    ) {
      //clear result before making request
      console.log("getHoroscopeAnalysis:request " + inputDate + " " + globalData.inputDate)

      get(`api/birthday/horoscope/${inputDate}/ismale/${isMale}?t=${Date.now()}`).then(res => {
        if (res.data.success) {
          if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {

            //update all values in the ref object
            lastGetConditionHoroscope.current = {
              inputDate,
              isMale
            }

            setHorosopeResult(res.data.data)
            console.log("HorosopeResult result is ready.")
          } else {
            console.log("getHoroscopeAnalysis birthday " + res.data.data.birthday + " is not up to date.")
          }
        }
      })
    } else {
      //request is not needed
    }
  }
  //#endregion


  /**
   * Get 原局 from server
   */
  const getBirthDateFromServer = () => {
    if (inputDate && isMale !== undefined) {
      get(`api/birthday/chinesedate/${inputDate}/ismale/${isMale}?t=${Date.now()}`).then(res => {
        if (res.data && res.data.success) {
          if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
            if (res.data.data && res.data.data.letterAddress && res.data.data.letterAddress.string
              && res.data.data.letterAddress.string.length > 5) {
              setSelectedLetter(res.data.data.letterAddress.string[4])  //default select 日元
            }

            setEightLettersResult(res.data.data)
            console.log("1/3: Birthday result is ready")
          } else {
            console.log("EightLetters birthday " + res.data.data.birthday + " is not up to date.")
          }
        } else {
          console.log("EightLetters birthday is null.")
        }
      })
    }
  }

  const scrollToHideDate = () => {
    console.log("scrolling date")
    if (dateRef.current) {
      // Get the position of the date div
      const dateDivPosition = dateRef.current.getBoundingClientRect().bottom + window.scrollY;
      console.log("dateDivPosition " + dateDivPosition)

      // Scroll the window to hide the date div
      document.querySelector("body").scrollTo({
        top: dateDivPosition,
        behavior: 'smooth'
      });
    }
  };


  //#region tab page
  const tabDefinition = [
    { name: '基本', tabContent: () => getSimpleUI() },
    { name: '進階', tabContent: () => getAdvancedUI() },
    { name: '用神', tabContent: () => getUseGod() },
    { name: '論命', tabContent: () => getAccessment() },
    //  { name: '斷事', tabContent: () => getEvents() },
    { name: '提示', tabContent: () => getHint() },

  ];

  function getTabs() {
    return tabDefinition.map((definition, index) => ({
      title: definition.name,
      getContent: () => definition.tabContent(),
      /* Optional parameters */
      key: index,
      tabClassName: 'tab',
      panelClassName: 'panel',
      transform: false
    }));
  }




  const getSimpleUI = () => {
    return (
      <Simple
        inputDate={inputDate}
        isMale={isMale}
        extraInfoMask={extraInfoMask}
        selectedYear10={selectedYear10}
        selected10YearRange={selected10YearRange}
        yearfortune100Result={yearfortune100Result}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        eightLettersResult={eightLettersResult}
        setEightLettersResult={setEightLettersResult}
        selectedLetterIndex={selectedLetterIndex}
        setSelectedLetterIndex={setSelectedLetterIndex}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
        year10Ready={year10Ready}

        setSelectedYear10={setSelectedYear10}

        selectedYear10IndexNumber={selectedYear10IndexNumber}
        //setYearfortune100Result={setYearfortune100Result}
        addressSchema={addressSchema}
        setYear10Ready={setYear10Ready}
        setSelected10YearRange={setSelected10YearRange}
        setSelectedYear10IndexNumber={setSelectedYear10IndexNumber}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}

        // to indicate wither deviated letter are ready
        isDLetterReady={isDLetterReady}
        setIsDLetterReady={setIsDLetterReady}

        // for mson selector
        // share from eightLetter to horoscope tab page
        msonIdPartyShowList={msonIdPartyShowList}
        setMsonIdPartyShowList={setMsonIdPartyShowList}
        letterAddressAllDateObj={letterAddressAllDateObj}
        setLetterAddressAllDateObj={setLetterAddressAllDateObj}

        //analysis result from index page
        analysisResult={analysisResult}
        setAnalysisResult={setAnalysisResult}
        setIsAnalysisReady={setIsAnalysisReady}
        getAnalysisOriginal={getAnalysisOriginal}

        //loading spinner
        LoadingSpinner={LoadingSpinner}
      />
    )
  }

  const getAdvancedUI = () => {
    return (
      <Advanced
        inputDate={inputDate}
        isMale={isMale}
        extraInfoMask={extraInfoMask}
        selectedYear10={selectedYear10}
        selected10YearRange={selected10YearRange}
        yearfortune100Result={yearfortune100Result}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        eightLettersResult={eightLettersResult}
        setEightLettersResult={setEightLettersResult}
        selectedLetterIndex={selectedLetterIndex}
        setSelectedLetterIndex={setSelectedLetterIndex}
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
        year10Ready={year10Ready}

        setSelectedYear10={setSelectedYear10}

        selectedYear10IndexNumber={selectedYear10IndexNumber}
        //setYearfortune100Result={setYearfortune100Result}
        addressSchema={addressSchema}
        setYear10Ready={setYear10Ready}
        setSelected10YearRange={setSelected10YearRange}
        setSelectedYear10IndexNumber={setSelectedYear10IndexNumber}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}

        // to indicate wither deviated letter are ready
        isDLetterReady={isDLetterReady}
        setIsDLetterReady={setIsDLetterReady}
        // for mson selector
        // share from eightLetter to horoscope tab page
        msonIdPartyShowList={msonIdPartyShowList}
        setMsonIdPartyShowList={setMsonIdPartyShowList}
        letterAddressAllDateObj={letterAddressAllDateObj}
        setLetterAddressAllDateObj={setLetterAddressAllDateObj}

        analysisResult={analysisResult}
        setAnalysisResult={setAnalysisResult}
        setIsAnalysisReady={setIsAnalysisReady}
        getAnalysisOriginal={getAnalysisOriginal}

        //loading spinner
        LoadingSpinner={LoadingSpinner}
      />
    )
  }


  const getUseGod = () => {
    return (
      <UseGod
        inputDate={inputDate}
        isMale={isMale}

        horosopeResult={horosopeResult}
        getHoroscopeAnalysis={getHoroscopeAnalysis}

        //loading spinner
        LoadingSpinner={LoadingSpinner}
      />
    );
  }
  const getAccessment = () => {
    return <Accessment
      inputDate={inputDate}
      isMale={isMale}
      //loading spinner
      LoadingSpinner={LoadingSpinner}
    />
  }
  


  const getEvents = () => {
    return <Events
      //loading spinner
      LoadingSpinner={LoadingSpinner}
    />
  }

  const getHint = () => {
    return (<Hint
      inputDate={inputDate}
      isMale={isMale}
      eightLettersResult={eightLettersResult}
      //loading spinner
      LoadingSpinner={LoadingSpinner}
    />


    )
  }

  //#endregion

  /**
   * Reusable loading spinner component
   */
  function LoadingSpinner(height) {
    let heightText = height && height.height&& height.height.length>0?height.height:"200px"
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: heightText}}>
        <CircularProgress />
      </div>
    );
  }


  return (
    inputDate ?
      <>
        {/* tab page for date input */}
        <Banner />
        <div className={styles.gridContainerSingleRowLayoutSpaced} ref={dateRef}>
          <ClientDataInput
            setInputDate={setInputDate} inputDate={inputDate}
            isMale={isMale} setIsMale={setIsMale}
          />
        </div>
        {/* tab page of all content */}
        <Tabs items={getTabs()} transform={false} />
      </>
      :
      <Main
        //main page, welcome page
        setInputDate={setInputDate} inputDate={inputDate}
        isMale={isMale} setIsMale={setIsMale}
      />
  );



} 