import React from 'react';
import styles from './index.module.css';
import logo192 from '../../images/LogoCorner.png'; // Adjust the path as needed
import iglogo from '../../images/IGLogo.png'; // Adjust the path as needed

const Banner = () => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <a href="https://www.instagram.com/hk1hy1" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
          <img src={logo192} alt="Logo" className={styles.logoWidth} />
        </a>
        <span className={styles.title}>
          子平八字排盤 - Adacs X{' '}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://www.instagram.com/hk1hy1', '_blank')}
          >
            秋海棠八字紫微命理
            <img width="14px" src={iglogo} alt="Instagram Logo" />
          </span>
        </span>
      </div>
    </nav>
  );
};

export default Banner;